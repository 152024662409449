import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout.js";
import Image from "../components/image.js";

const Provider = ({ pageContext }) => {
  const {
    slug,
    name,
    categories,
  } = pageContext
  console.log(categories);
  return <Layout infos={{
    title: name,
    description: "Browse all icons from "+name+". Discover "+name+" icons categories and download high quality icons in a variety of formats & sizes.",
  }}>
    <h1>Categories</h1>
    <ul id="categories">
    {
      Object.values(categories).map(category => {
        return <li key={category.slug}>
          <Link
            to={ "/"+slug+"/"+category.slug }
          >
            <div>
              {
                category.sample.map(icon => {
                  return <Image key={ icon } src={ "/"+icon } alt={ "Icon sample from "+name+" - "+category.name } />
                })
              }
            </div>
            <h2>{ category.name }</h2>
            <span data-provider={ slug }>
            { category.icons.length
              ? category.icons.length+" icons"
              : Object.keys(category.children).length+" categories"
            }</span>
          </Link>
        </li> 
      })
    }
    </ul>
  </Layout>
}

export default Provider;
